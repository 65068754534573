import React from 'react';
import NavBar from '../Components/NavBar';
import FooterBar from '../Components/FooterBar';
import ContentAbout from '../Components/ContentAbout';

const AboutPage = props => (
  <div className="home-container">
    <NavBar />
    <ContentAbout />
    <FooterBar />
  </div>
);

export default AboutPage;
