// import React from 'react';
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'
import pic2 from '../img/pic2.jpg'
import './NavBar.css'


function NavBar(props) {

    const [toggle, setToggle] = useState(false)


    return (
        <div>
            <nav className='navbarNav'>
                <div className='ink-logo'>
                    <Link exact to='/'>
                        <img src={pic2} alt="ink-pressive" />
                    </Link>
                </div>
                <ul className='nav-links' style={{ transform: toggle ? "translateX(0px)" : "" }} >
                    <li><NavLink exact to='/' >Home</NavLink></li>
                    <li><NavLink exact to='/about' >About</NavLink></li>
                    <li><NavLink exact to='/services' >Services</NavLink></li>
                    <li><NavLink exact to='/contact' >Contact</NavLink></li>
                </ul>
                <i onClick={() => setToggle(!toggle)} className="fas fa-bars burger" ></i>
            </nav>
        </div>
    );
}

export default NavBar;