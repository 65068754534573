import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import AboutPage from '../Pages/AboutPage';
import ServicePage from '../Pages/ServicePage';
// import Dashboard from '../CmsAdmin/DashBoard';
import ContactPage from '../Pages/ContactPage';
// import InkPressiveCMSRoutes from './InkPressiveCMSRoutes';
// import NewCMSNav from '../CmsAdmin/NewCMSNav';
// import CMSHomePage from '../CmsAdmin/CMSHomePage';
// import ParentCMSRoutes from './ParentCMSRoutes';
// import ContactMessCMS from '../CmsAdmin/ContactMessCMS';

const InkPressiveRoutes = props => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          render={routeProps => <HomePage {...routeProps} />}
        />
        <Route
          exact
          path="/about"
          render={routeProps => <AboutPage {...routeProps} />}
        />
        <Route
          exact
          path="/services"
          render={routeProps => <ServicePage {...routeProps} />}
        />
        <Route
          exact
          path="/contact"
          render={routeProps => <ContactPage {...routeProps} />}
        />
      </Switch>
    </div>
  );
};

export default InkPressiveRoutes;

//  <Route
//         exact
//         path="/admin/cmsContact"
//         render={routeProps => <ContactMessCMS {...routeProps} />}
//       />

//       <ParentCMSRoutes />
//        <InkPressiveCMSRoutes />
//        <NewCMSNav />
//   </Route>

/* <Route
        exact
        path="/cmsMain"
        render={routeProps => <CMSHomePage {...routeProps} />}
      /> */