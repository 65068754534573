import React from 'react';
import { Link } from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';
import ContactForm from '../Forms/ContactForm';
import editCups2 from '../img/editCups2.jpg';
import PhotoGallery from './PhotoGallery';
import UtilSEO from '../Components/utlies/UtilSEO';
import './ContentContact.css';


const ContentContact = () => {
  return (
    <div>
      <UtilSEO title={'Contact - Ink Pressive'} description={'Please reach out to us for any questions regarding screen printing, sublimation, vinyl and graphic design'} />
      <main className="contact-page-container">
        <div className="first-section">
          <h1>Reach out to us</h1>
          <br />
          <h3>have a question? detailes on our process?</h3>
          <br />
          <div className="form-instructions">
            <section className="instruction-text">
              <p>
                - Down below is our contact form, we invite everyone who has questions about
               our company and what we offer to send us a message. We love meeting new people
               and helping meet and exced the needs of everyone we work with. So just shoot us
               a message through our form or email us.
                <br />
                <br />
                Note: if you have a good idea of what you
               need, we invite you to checkout our service page. There you will find a form where
               you can sumbit a request of exactly what you need and we will get back to
               you asap. Also if we don't have anything listed for print or sizes that you're
               interested in, just add it to the bottom of the servive form or contact form. In
               most cases we can work with the items you're curious about.
               <br />
                <br />
                Thank you for your interest in our company
               <br />
                - InkPressive
              </p>
            </section>
          </div>
        </div>
        <br />
        <div className="second-section">
          <div className="Form-area">
            <ContactForm />
          </div>
          <div className="photo-number-section">
            <img className="contactImg" src={editCups2} alt="cups" />
            <a href="tel:2708046730" className='callButton'>
              Call Now <CallIcon />
            </a>
            {/* <Button className='callButton' variant="contained"
            // style={{ backgroundColor: 'green', color: 'white', fontSize: '25px', marginTop: '20px' }}
            >
              <a href="">Call Now</a>
            </Button> */}
            <h1>270-227-1369</h1>
            <h2>
              georgecruz@
              <br />
              gmail.com
            </h2>
          </div>
        </div>
        <div className="third-section">
          <h2>Know what you're looking for?</h2>
          <h1>Go to our service page</h1>
          <button>
            {' '}
            <Link exact to="/services">
              Click here
            </Link>{' '}
          </button>
        </div>
        <PhotoGallery />
      </main>
    </div>
  );
};

export default ContentContact;
