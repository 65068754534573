import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from '../firebase';
import 'firebase/firestore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import './ServiceForm.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '50px',
    background: 'linear-gradient(45deg, #6f838c 30%, rgb(98, 100, 102) 90%)'
  },
  shirtsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '5px',
    paddingLeft: '392px',
    paddingRight: '25px',
    paddingBottom: '10px'
  },
  InputBase: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1),
    color: 'white'
  },
  valInputBase: {
    border: '1px solid red',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    // padding: theme.spacing(1),
    color: 'white',
    marginLeft: '20px',
    padding: '10px',
    width: '25ch'
  },
  InputBase2: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    // padding: theme.spacing(1),
    color: 'white',
    padding: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  positionRoot: {
    marginLeft: '300px'
  },
  myH2: {
    fontFamily: 'abel',
    marginLeft: '10px',
    color: '#fff'
  },
  myH1: {
    fontFamily: 'abel',
    marginLeft: '10px',
    color: 'white'
  },
  errorText: {
    color: 'red'
  },
  chooseSizes: {
    border: '1px solid white',
    color: '#fff',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1)
  },
  multilineField: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    color: 'white',
    // width: '25ch'
    width: '100%',
    maxWidth: '950px'
  },
  buttonContainer: {
    marginLeft: '10px'
  },
  submitButtonStyle: {
    backgroundColor: 'lightslategray',
    color: 'white',
    marginTop: '10px',
    width: '100%',
    maxWidth: '950px'
  }
}));

const ServiceForm = () => {
  const classes = useStyles();

  const [textState, setTextState] = useState({
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    other: '',
    errorHandle: true,
    disabled: true,
    smallQty: 0,
    mediumQty: 0,
    largeQty: 0,
    xlargeQty: 0,
    xxlargeQty: 0,
    itemQty: ''
  });

  const history = useHistory()

  const handleFieldInputs = e => {
    setTextState({ ...textState, [e.target.name]: e.target.value });
  };

  const handleEmailInput = e => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let checkReg = re.test(textState.email);

    if (checkReg === false) {
      setTextState({
        ...textState,
        [e.target.name]: e.target.value,
        errorHandle: false,
        disabled: true
      });
    }

    if (checkReg === true) {
      setTextState({
        ...textState,
        [e.target.name]: e.target.value,
        errorHandle: true,
        disabled: false
      });
    }
  };

  const [uploader, setUploader] = useState(null);

  // !!!!Come back to this




  const [state, setState] = useState({
    tees: false,
    longSleeves: false,
    hoodies: false,
    other: false,
    screenPrinting: false,
    sublimation: false
  });

  const fileSelect = async (e) => {
    let file = e.target.files[0]
    const base64 = await encodeImg(file)
    setUploader(base64)
    // console.log(base64);
  };

  const encodeImg = (file) => {

    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        res(fileReader.result)
      };

      fileReader.onerror = (error) => {
        rej(error)
      };
    })

  }

  const handleCheckBox = e => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const HandleServiceSubmit = e => {
    e.preventDefault();
    const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

    firebase
      .firestore()
      .collection('Service_orders')
      .add({
        // need to stringIfy timeStamp
        message: {
          company: textState.company,
          firstName: textState.firstName,
          lastName: textState.lastName,
          email: textState.email,
          uploader: uploader,
          other: textState.other,
          small: textState.smallQty,
          medium: textState.mediumQty,
          large: textState.largeQty,
          xlargeQty: textState.xlargeQty,
          xxlargeQty: textState.xxlargeQty,
          tees: state.tees,
          longSleeves: state.longSleeves,
          hoodies: state.hoodies,
          screenPrinting: state.screenPrinting,
          sublimation: state.sublimation,
          customerMessage: textState.itemQty,
          createdAt: timeStamp()
        },
        hasSeen: false
      });

    setTextState({
      name: '',
      email: '',
      phoneNumber: '',
      message: '',
      errorHandle: true,
      disabled: true,
      smallQty: 0,
      mediumQty: 0,
      largeQty: 0,
      xlargeQty: 0,
      xxlargeQty: 0
    });
    setUploader([]);
    setState({
      tees: false,
      longSleeves: false,
      hoodies: false,
      other: false,
      screenPrinting: false,
      sublimation: false
    });
    history.push('/')
  };

  // console.log(uploader);

  return (
    <div className={classes.root}>
      <form onSubmit={HandleServiceSubmit}>
        <div className="testDiv">
          <div>
            <h1 className={classes.myH1}>Talk to us</h1>
            <InputBase
              id="outlined-full-width"
              className={classes.InputBase}
              style={{ margin: 8 }}
              onChange={handleFieldInputs}
              placeholder="Company"
              name="company"
              fullWidth
              variant="outlined"
            />
            <br />
            <br />
            <InputBase
              id="outlined-margin-none"
              onChange={handleFieldInputs}
              placeholder="First Name"
              name="firstName"
              className={classes.InputBase2}
              variant="outlined"
            />
            <InputBase
              id="outlined-margin-dense"
              onChange={handleFieldInputs}
              placeholder="Last Name"
              name="lastName"
              className={classes.InputBase2}
              variant="outlined"
            />
            <InputBase
              id="outlined-margin-dense"
              onChange={handleEmailInput}
              placeholder="Your E-mail"
              name="email"
              className={
                textState.errorHandle === false
                  ? classes.valInputBase
                  : classes.InputBase2
              }
              variant="outlined"
            />
            <br />
            <br />
            <span className={classes.errorText}>
              {textState.errorHandle === false ? 'Email not valid' : ''}
            </span>
          </div>
          <div>
            <br />
            <h2 className={classes.myH2}>Have Print Grade Art Work?</h2>
            <h5 className={classes.myH2}>File size must be under 100kb!!</h5>
            {/* <h6 className={classes.myH2}> Must Be Print Grade!</h6> */}
            <InputBase
              id="outlined-normal"
              type="file"
              onChange={(e) => fileSelect(e)}
              placeholder="Upload File"
              className={classes.InputBase2}
              style={{ margin: 8 }}
              variant="outlined"
            />
          </div>
          <br></br>
          <div className={classes.myH1}>
            <h1>Types of Shirts</h1>
            <h5 > Choose One</h5>
            <FormControlLabel
              checked={state.tees}
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  color="default"
                  name="tees"
                />
              }
              label="Tees"
            />
            <FormControlLabel
              checked={state.longSleeves}
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  color="default"
                  name="longSleeves"
                />
              }
              label="Long Sleeves"
            />
            <FormControlLabel
              checked={state.hoodies}
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  color="default"
                  name="hoodies"
                />
              }
              label="Hoodies"
            />
            <FormControlLabel
              checked={state.other}
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  color="default"
                  name="other"
                />
              }
              label="Other"
            />
            <InputBase
              className={classes.chooseSizes}
              value={state.checked}
              onChange={handleFieldInputs}
              label="Other"
              id="standard-size-small"
              placeholder="Description"
              size="small"
              name="otherField"
            />
          </div>
          <div className={classes.myH1}>
            <h2 className={classes.myH1}>Print Type</h2>
            <FormControlLabel
              checked={state.screenPrinting}
              control={<Checkbox onChange={handleCheckBox} color="default" />}
              name="screenPrinting"
              label="Screen Printing"
            />
            <FormControlLabel
              checked={state.sublimation}
              control={<Checkbox onChange={handleCheckBox} color="default" />}
              name="sublimation"
              label="Sublimation"
            />
          </div>
          <br />
          <div className={classes.myH1}>
            <h2 className={classes.myH1}>Choose Sizes</h2>
            <h4 className={classes.myH2}>Max Order 150 items</h4>
            <br />
            <InputBase
              className={classes.chooseSizes}
              placeholder="Small Qty"
              name="smallQty"
              onChange={handleFieldInputs}
              label="Size-small"
              id="standard-size-small"
              size="small"
            />{' '}
            <InputBase
              className={classes.chooseSizes}
              placeholder="Medium Qty"
              name="mediumQty"
              onChange={handleFieldInputs}
              label="Size-medium"
              id="standard-size-small"
              size="small"
            />{' '}
            <InputBase
              className={classes.chooseSizes}
              placeholder="Large Qty"
              name="largeQty"
              onChange={handleFieldInputs}
              label="Size-large"
              id="standard-size-small"
              size="small"
            />{' '}
            <InputBase
              className={classes.chooseSizes}
              placeholder="X-large Qty"
              name="xlargeQty"
              onChange={handleFieldInputs}
              label="Size-XL"
              id="standard-size-small"
              size="small"
            />{' '}
            <InputBase
              className={classes.chooseSizes}
              placeholder="2X-large Qty"
              name="xxlargeQty"
              onChange={handleFieldInputs}
              label="Size-XXL"
              id="standard-size-small"
              size="small"
            />
          </div>
          <br />
          <div>
            <h4 className={classes.myH2}> We do Sizes Toddler to 5X</h4>
          </div>
          <br />
          <br />
          <div className={classes.myH1}>
            <h1>Mask, Mugs, Koozies Etc</h1>
            <h4>Also use this area for unlisted Sizes and items</h4>
            <br />
            <InputBase
              label="Items and Qty"
              className={classes.multilineField}
              multiline
              rows={15}
              id="outlined-margin-normal"
              onChange={handleFieldInputs}
              placeholder="Items and Qty"
              name="itemQty"
              variant="outlined"
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              type="submit"
              disabled={textState.disabled}
              className={classes.submitButtonStyle}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ServiceForm;
