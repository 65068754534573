import React from 'react';
import { AuthProvider } from './context/AuthContext'
import InkPressiveRoutes from './Routes/InkPressiveRoutes';
import InkPressiveCMSRoutes from './Routes/InkPressiveCMSRoutes';

// We now need a protect route for the Admin/CMS panel so when the client types in
// the correct address they're met with a login page before they can access the panel

function App(props) {
  return (
    <div>
      <InkPressiveRoutes />
      <AuthProvider>
        <InkPressiveCMSRoutes />
      </AuthProvider>
    </div>
  );
}

export default App;
