import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        },

        paddingTop: '80px'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        boxShadow: '0 30px 30px -6px black',
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: '#4c535c'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    submit: {
        marginTop: theme.spacing(3)
    }
}));

export function Login(props) {
    const classes = useStyles();
    const [useInfo, setUseInfo] = useState({
        email: '',
        password: ''
    })
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleChange(e) {
        setUseInfo({ ...useInfo, [e.target.name]: e.target.value })
    }

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(useInfo.email, useInfo.password);
            history.push('/cmsContact')
        } catch {
            setError("Invalid Credientials")
        }
        setLoading(false)
    }

    return (
        <div style={{ backgroundColor: 'grey', height: '100vh', }}>
            <main className={classes.main} >
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon style={{ backgroundColor: '#4c535c' }} />
                    </Avatar>
                    <Typography variant="h5"> Sign In </Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <form className={classes.form} onSubmit={handleSubmit} >
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">email</InputLabel>
                            <Input id="email" name="email" onChange={handleChange} required />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">password</InputLabel>
                            <Input id="password" type='password' name="password" onChange={handleChange} required />
                        </FormControl>

                        <Button
                            style={{ backgroundColor: 'grey', color: 'white' }}
                            disabled={loading}
                            variant="contained"
                            type="submit"
                            fullWidth
                            className={classes.submit}
                        >
                            Sign In
                    </Button>
                    </form>
                </Paper>
            </main>
        </div>
    );
};

export default Login;