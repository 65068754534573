import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import 'firebase/firestore';
import PhotoModal from './PhotoModal';
import './PhotoGallery.css';




const PhotoGallery = (props) => {


  useEffect(() => {
    const unSub = firebase
      .firestore()
      .collection('cmsPhotosUrls')
      // .orderBy('createdAt', 'desc')
      .onSnapshot(snap => {
        let documents = [];
        snap.forEach(doc => {
          documents.push({ ...doc.data(), id: doc.id });
        });
        setGrabData(documents[0].cmsPhotosUrls.photoUrls);
      });
    return () => unSub();

  }, []);

  const [grabData, setGrabData] = useState([])

  // console.log(grabData.photoUrls);

  // const fetched = grabData.photoUrls


  const checkLoop = grabData.map((p, idx) => (
    <div key={idx} className="pictureIndiv">
      <div className="photoShadowInsta">
        <div >
          {/* <img src={p.photo} alt={p.photo} className="srcPics" /> */}
          <PhotoModal photo={p.photo} url={p.url} />

        </div>
      </div>
    </div>
  ));
  // return checkLoop
  // }

  // console.log(grabData);
  // Quick note: the idea to be able to use instagram is to upload the picture with
  // base64 on the cms to firbase along with the Embeded link. In the cms the picture and the
  // link will live in an object together in an array of objects. Once we request
  // the array from firebase we will diplay the pictures and on each picture we will
  // have a modal for when clicked will show the instagram Embeded link

  return (
    <div className="photoGalleryContainer">
      {/* <h1 className="galleryTitle">Our Work</h1> */}
      <div className="secondaryContainerPhoto">
        {checkLoop}
      </div>
    </div>
  );
};

export default PhotoGallery;



// {
//   grabData ? (grabData.photoUrls.map((p, idx) => (
//     <div key={idx} className="pictureIndiv">
//       <div className="photoShadowInsta">
//         <img src={p.photo} alt={p.photo} className="srcPics" />
//       </div>
//     </div>
//   ))) : ''
// }



  // const checkLoop = photpArray.map(p => (
  //   <div className="pictureIndiv">
  //     <div className="photoShadowInsta">
  //       {/* <div style={{
  //         backgroundImage: `url(${p})`, backgroundRepeat: 'no-repeat',
  //         backgroundSize: 'cover', objectFit: 'cover'
  //       }} alt={p} className="srcPics" /> */}
  //       <img src={p} alt={p} className="srcPics" />
  //     </div>
  //   </div>
  // ));


  // async function fetchData() {
  //   await firebase
  //     .firestore()
  //     .collection('cmsPhotosUrls')
  //     // .orderBy('createdAt', 'desc')
  //     .onSnapshot(snap => {
  //       let documents = [];
  //       snap.forEach(doc => {
  //         documents.push({ ...doc.data(), id: doc.id });
  //       });
  //       setGrabData(documents[0].cmsPhotosUrls);
  //     });
  // }