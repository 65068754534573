import React, { useEffect, useState } from 'react';
import pic2 from '../img/pic2.jpg'
import firebase from '../firebase';
import 'firebase/storage';
import 'firebase/firestore';
import './DisplayCMSSection.css'


function DisplayCMSSection(props) {

    const [grabData, setGrabData] = useState([])

    useEffect(() => {
        const unSub = firebase
            .firestore()
            .collection('cmsSection')
            // .orderBy('createdAt', 'desc')
            .onSnapshot(snap => {
                let documents = [];
                snap.forEach(doc => {
                    documents.push({ ...doc.data(), id: doc.id });
                });
                setGrabData(documents[0].cmsSection);
            });
        return () => unSub();
    }, []);


    // console.log(grabData);
    /* <div className="cms-section" style={{ backgroundImage: `url(${grabData.imageBackground})` }}> */

    return (
        <div className="cms-section" style={{ backgroundImage: `url(${pic2})` }}>
            <h2 className='cmsSectionHeader'> {grabData.heading} </h2>
            <br />
            <h4 className='body_Textbody'>
                {grabData.body}
            </h4>
            <div className='cmsPhotoContainer'>
                <img className='cmsPhotoFetch' alt='deal offerings one' src={grabData.imageItem_1} />
                <img className='cmsPhotoFetch' alt='deal offerings two' src={grabData.imageItem_2} />
                <img className='cmsPhotoFetch' alt='deal offerings three' src={grabData.imageItem_3} />
                <img className='cmsPhotoFetch' alt='deal offerings four' src={grabData.imageItem_4} />
            </div>
        </div>
    );
}

export default DisplayCMSSection;