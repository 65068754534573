import React from 'react';
import ServiceForm from '../Forms/ServiceForm';
import PhotoGallery from './PhotoGallery';
import UtilSEO from '../Components/utlies/UtilSEO';
import './ContentService.css';

const ContentService = () => {
  return (
    <div>
      <UtilSEO title={'Sublimation Services - Ink Pressive'} description={'Ink Pressive offers services in sublimation, screen printing, vinyl and graphic design! The items we can use are shirts, hats, mugs, koozies, masks and more!'} />
      <main className="ServiceContainer">
        <div className="shadowOfferedServ">
          <div className="offerdServ">
            <h2>OUR SERVICES</h2>
            <div className="serviceListItems">
              <div className="serItem1">
                <li className="servGrow">
                  {' '}
                  <i className="fas fa-adjust"></i> - SUBLIMATION{' '}
                  <p>
                    Using Black Magic and Powers of Zeus this process is
                    amazing. We can print on tons of objects. Unlike screen
                    printing this ink binds into the fibers instead of on top.
                  </p>{' '}
                </li>
                <li className="servGrow">
                  <i className="fab fa-500px"></i> - SCREEN PRINTING{' '}
                  <p>
                    We screen print what ever you think you might want. Shirts.
                    Hand pressed with up to 6 Colors for each design. These have
                    a minimum order due to the process taking so much.
                  </p>
                </li>
              </div>
              <div className="serItem2">
                <li className="servGrow">
                  <i className="fas fa-compress"></i> - VINYL{' '}
                  <p>
                    Staying true to local business we buy all of our vinyl
                    locally. We can make decals for cars, cups, or pretty much
                    what ever else you still vinyl on. We have a large variety
                    of colors and options.{' '}
                  </p>
                </li>
                <li className="servGrow">
                  <i className="fas fa-crop-alt"></i> - GRAPHIC DESIGN{' '}
                  <p>
                    A machine that cuts out designs, thena heat press is
                    utilized to transfer each piece of vinyl onto the material.{' '}
                  </p>
                </li>
              </div>
            </div>
            <h2 className="turnAroundH">
              {' '}
              <i className="far fa-clock"></i> FAST TURN AROUND 1/2-2 WEEKS
              (COVID DELAYS)
            </h2>
            <a className='vectorFree' href="https://www.vecteezy.com/free-vector/vector">Vector Vectors by Vecteezy</a>
          </div>
        </div>
        <div className="typeDesContainer">
          <div className="svgBack">
            <div className="iconsSvg">
              <h1>
                SHIRTS/HATS/MUGS<br></br>KOOZIES/MISC.
              </h1>
              <p>
                Need something more specific? We can print on Koozies, Tote
                Bags, Dog tages & more...
              </p>
              <br />
              <p>
                How about Mask for your upcoming events? Or Covers for your
                band's demo or record? Wedding Invitations? Specialized
                packaging for your new invention? Screen printing can give that
                home-grown feel and extra detail that makes your project really
                stand out.
              </p>
            </div>
          </div>
          <div className="graphicBack">
            <div className="grahicDesg">
              <h1>
                Designs/Graphics
                <br />
                <i className="far fa-object-group"></i>{' '}
                <i className="fas fa-drafting-compass"></i>
              </h1>
              <p>
                Firefighter/Paramedic by trade I do not have a degree in Graphic
                Design. I keep a graphic artist on retainer and pay for anything
                I am not capable of out of my pocket. That doesn’t mean I cannot
                come out with with some dope stuff. Check our socials for what
                we do!
              </p>
              <br />
              <p>
                There are no set rates currently because the designs vary in
                detail. Just send us a message and we can work something out.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="servFormContainer-form" style={{ margin: '40px' }}>
          <ServiceForm />
        </div>
        <PhotoGallery />
      </main>
    </div>
  );
};

export default ContentService;
