import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

// Working through issue with instagram post image and caption not loading in

const PhotoModal = ({ photo, url }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    console.log(url);
    return (
        <div>
            <img src={photo} alt={photo} className="srcPics" onClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {/* <div dangerouslySetInnerHTML={{ __html: url }}>
                        </div> */}
                        {/* <iframe src='https://www.instagram.com/p/CIE9-KgFXIl/embed/captioned' width="500" height="580" frameborder="0" scrolling="no" allowtransparency="true"></iframe> */}
                        <embed src={`${url}embed/captioned`} width="500" height="580" frameborder="0" scrolling="no" allowtransparency="true"></embed>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default PhotoModal



// Old modal with react spring below
// import PropTypes from 'prop-types';
// import { useSpring, animated } from 'react-spring/web.cjs'; 
// web.cjs is required for IE 11 support

// const useStyles = makeStyles((theme) => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     paper: {
//         backgroundColor: theme.palette.background.paper,
//         // border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3),
//     },
// }));


// const Fade = forwardRef(function Fade(props, ref) {
//     const { in: open, children, onEnter, onExited, ...other } = props;
//     const style = useSpring({
//         from: { opacity: 0 },
//         to: { opacity: open ? 1 : 0 },
//         onStart: () => {
//             if (open && onEnter) {
//                 onEnter();
//             }
//         },
//         onRest: () => {
//             if (!open && onExited) {
//                 onExited();
//             }
//         },
//     });

//     return (
//         <animated.div ref={ref} style={style} {...other}>
//             {children}
//         </animated.div>
//     );
// });

// Fade.propTypes = {
//     children: PropTypes.element,
//     in: PropTypes.bool.isRequired,
//     onEnter: PropTypes.func,
//     onExited: PropTypes.func,
// };

// function PhotoModal(props) {
//     const classes = useStyles();
//     const [open, setOpen] = useState(false);

//     // useEffect(() => {
//     //     window.instgrm.Embeds.process()
//     // }, [])

//     const handleOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const blockInstaCheck = (

//         <div dangerouslySetInnerHTML={{ __html: props.url }} />
//         // </div >
//     )

//     // console.log(blockInstaCheck);

//     return (
//         <div>
//             <img src={props.photo} alt={props.photo} className="srcPics" onClick={handleOpen} />
//             <Modal
//                 aria-labelledby="spring-modal-title"
//                 aria-describedby="spring-modal-description"
//                 className={classes.modal}
//                 open={open}
//                 onClose={handleClose}
//                 closeAfterTransition
//                 BackdropComponent={Backdrop}
//                 BackdropProps={{
//                     timeout: 500,
//                 }}
//             >
//                 <Fade in={open}>
//                     <div className={classes.paper}>
//                         {/* <div dangerouslySetInnerHTML={{ __html: props.url }}>
//                         </div> */}
//                         {blockInstaCheck}
//                     </div>
//                 </Fade>
//             </Modal>
//         </div>
//     );
// }