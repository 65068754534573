import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from '../firebase';
import 'firebase/firestore';
// import 'firebase/database';
// import FireBaseUse from '../firebase';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './ContactForm.css';

// firebase.initializeApp(firebase);

// firebase.initializeApp({
//   apiKey: 'AIzaSyA6kvMSc5-Cq7f8mn8RVt3IR0QIlbjYCag',
//   authDomain: 'ink-pressive-dev.firebaseapp.com',
//   databaseURL: 'https://ink-pressive-dev-default-rtdb.firebaseio.com',
//   projectId: 'ink-pressive-dev',
//   storageBucket: 'ink-pressive-dev.appspot.com',
//   messagingSenderId: '492433183742',
//   appId: '1:492433183742:web:ba03e80e9ea4a31e3226af'
// });

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '40px',
    background: 'linear-gradient(45deg, #6f838c 30%, rgb(98, 100, 102) 90%)'
  },
  formBox: {
    width: '100%',
  },
  contactH1: {
    fontFamily: 'abel',
    marginLeft: '10px',
    color: 'white',
    fontSize: 'clamp(35px, 5vw, 72px)'
    // fontSize: '4.5rem'
  },
  contactH2: {
    fontFamily: 'abel',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '10px',
    color: 'white'
  },
  InputBase: {
    border: '1px solid white',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1),
    color: 'white',
    width: '100%',
    maxWidth: '600px'
  },
  valInputBase: {
    border: '1px solid red',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1),
    color: 'white',
    width: '100%',
    maxWidth: '600px',
    textDecoration: 'none'
  },
  errorText: {
    color: 'red'
  },
  multilineField: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    color: 'white',
    width: '100%',
    maxWidth: '600px'
  },
  contactButton: {
    margin: theme.spacing(1),
    magingRight: '10px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    width: '100%',
    maxWidth: '600px'
  }
}));

const ContactForm = (props) => {
  const classes = useStyles();

  // const messageRef = firestore.collection('messages');
  const [stateContact, setStateContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    errorHandle: true,
    disabled: true
  });

  const history = useHistory()

  const handleFieldInputs = e => {
    setStateContact({ ...stateContact, [e.target.name]: e.target.value });
  };

  const handleEmailInput = e => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(re.test(stateContact.email));

    let checkReg = re.test(stateContact.email);

    if (checkReg === false) {
      setStateContact({
        ...stateContact,
        [e.target.name]: e.target.value,
        errorHandle: false,
        disabled: true
      });
    }

    if (checkReg === true) {
      setStateContact({
        ...stateContact,
        [e.target.name]: e.target.value,
        errorHandle: true,
        disabled: false
      });
    }
  };

  const handleContactSubmit = async e => {
    e.preventDefault();
    const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
    firebase
      .firestore()
      .collection('Contact_MSG')
      .add({
        // need to stringIfy timeStamp
        message: {
          name: stateContact.name,
          email: stateContact.email,
          phoneNumber: stateContact.phoneNumber,
          message: stateContact.message,
          // hasSeen: false,
          createdAt: timeStamp()
        },
        hasSeen: false
      });
    setStateContact({
      name: '',
      email: '',
      phoneNumber: '',
      message: '',
      errorHandle: true,
      disabled: true
    });
    history.push('/')
  };
  // FireBaseUse();
  // console.log(props);

  return (
    <div className={classes.root}>
      <form className={classes.formBox} onSubmit={handleContactSubmit}>
        <h1 className={classes.contactH1}>Contact us</h1>
        <h2 className={classes.contactH2}>Name</h2>
        <InputBase
          id="outlined-full-width"
          className={classes.InputBase}
          style={{ margin: 8 }}
          onChange={handleFieldInputs}
          placeholder="Name"
          name="name"
          fullWidth
          variant="outlined"
        />
        <span></span>
        <h2 className={classes.contactH2}>E-mail</h2>
        <InputBase
          id="outlined-full-width"
          // id="component-error"
          className={
            stateContact.errorHandle === false
              ? classes.valInputBase
              : classes.InputBase
          }
          style={{ margin: 8 }}
          onChange={handleEmailInput}
          placeholder="E-mail"
          name="email"
          fullWidth
          variant="outlined"
        />
        <span className={classes.errorText}>
          {stateContact.errorHandle === false ? 'Email not valid' : ''}
        </span>
        <h2 className={classes.contactH2}>Phone Number</h2>
        <InputBase
          id="outlined-full-width"
          className={classes.InputBase}
          style={{ margin: 8 }}
          onChange={handleFieldInputs}
          placeholder="Phone Number"
          name="phoneNumber"
          fullWidth
          variant="outlined"
        />
        <span></span>
        <h2 className={classes.contactH2}>Message</h2>
        <InputBase
          label="Items and Qty"
          className={classes.multilineField}
          style={{ margin: 8 }}
          multiline
          rows={15}
          id="outlined-margin-normal"
          onChange={handleFieldInputs}
          placeholder="Message"
          name="message"
          variant="outlined"
        />
        <span></span>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={stateContact.disabled}
          color="primary"
          className={classes.contactButton}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;

// const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// // console.log(re.test(stateContact.email));

// let checkReg = re.test(stateContact.email);

// if (checkReg === false) {
//     setStateContact({ ...stateContact, [e.target.name]: e.target.value, errorHandle: false })
// }

// if (checkReg === true) {
//     setStateContact({ ...stateContact, [e.target.name]: e.target.value, errorHandle: true })
// }

// // return re.test(stateContact.email);

// if (stateContact.errorHandle === true && !stateContact.email === '') {
//     setStateContact({ ...stateContact, [e.target.name]: e.target.value, errorHandle: true, disabled: false })
// }

// return re.test(stateContact.email);
