import React from 'react';
import ContentService from '../Components/ContentService';
import NavBar from '../Components/NavBar';
import FooterBar from '../Components/FooterBar';

const ServicePage = () => {
  return (
    <div>
      <NavBar />
      <ContentService />
      <FooterBar />
    </div>
  );
};

export default ServicePage;
