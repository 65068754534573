import React from 'react';
import { Link } from 'react-router-dom';
import PhotoGallery from './PhotoGallery';
import UtilSEO from '../Components/utlies/UtilSEO';
import './ContentAbout.css';

const ContentAbout = () => {
  return (
    <div>
      <UtilSEO title={'Screen Printing - Ink Pressive'} description={'Ink Pressive make Hand Printed High Quality Custom Shirts, Mask, Apparel & More! Our methods are Screen Printing, Sublimation, and Vinyl '} />
      <main className="AboutMainPage">
        <div className="picAboutSection">
          <div className="main-PicArea">
            <div className="picArea-1">
              {/* <img src={toyMask} alt="toy with mask" /> */}
              <p className="toyMask-Caption">
                Hand Printed High Quality Custom Shirts, Mask, Apparel & More!
              </p>
            </div>
            <div className="picArea-2">
              {/* <img src={weather} alt="text area" /> */}
              <p className="skulls-Caption">
                <h2>Family Owned & Operated</h2>Ink Pressive Screen Printing is
                owned and operated by George and Latisha Cruz
              </p>
            </div>
          </div>
          <div className="usAbout">
            <div className="AboutUs-Contents">
              <h1>About Us</h1>
              <h3>Our Passion</h3>
              <br />
              <article>
                Ink Pressive is a proudly owned First Responder screen printing,
                sublimation, and vinyl shop focused on attention to details more
                then quantity. We realized in our local area that most print
                shops details sucked, and the customer service seriously lacked.
                Dealing with these shops was like nails on a chalkboard. Not Me.
                Got a question, message or call. Maybe an idea in your head but
                not sure it will work. Hey, that’s alright let’s see what we can
                do.
              </article>
              <br />
              <article>
                Printing by hand allows me to focus on quality. When I do not
                like how a print turns out I throw it out. If I think a design
                may not work, the customer will always know. With a focus on
                quality, I order Ink I know that I like, plus shirts that will
                last. If a print looks crap, I turn it into a shop rag. Simple
                as that.
              </article>
            </div>
          </div>
        </div>
        {/* <div className="photo-Gall"> */}
        {/* <p>Photo Gallory Area</p> */}
        <PhotoGallery />
        {/* </div> */}
        <h1 className="message-us">Message us</h1>
        <Link exact to="/services">
          <button>Lets Talk</button>
        </Link>
      </main>
    </div>
  );
};

export default ContentAbout;
