import React from 'react';
import ContentContact from '../Components/ContentContact';
import NavBar from '../Components/NavBar';
import FooterBar from '../Components/FooterBar';

const ContactPage = () => {
  return (
    <div>
      <NavBar />
      <ContentContact />
      <FooterBar />
    </div>
  );
};

export default ContactPage;
