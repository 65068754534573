import React from 'react';
import { Link } from 'react-router-dom'
// import UtilSEO from '../utlies/UtilSEO';
import UtilSEO from '../Components/utlies/UtilSEO';
import beetleShirt from '../img/beetleShirt.jpg';
import basicWitch from '../img/basicWitch.jpg';
import onesy from '../img/onesy.jpg';
import PhotoGallery from './PhotoGallery';
import DisplayCMSSection from './DisplayCMSSection';
import './ContentComp.css';


function ContentComp(props) {
  return (
    <div>
      <main className="HomeContent">
        <UtilSEO title={'Ink Pressive - Ink Pressive'} description={'Ink Pressive, a Screen Printing shop in western kentucky owned and operated by First Responders'} />
        <div className="introduction-title">
          <h3 className='title-location'>Western/Murray Ky</h3>
          <br />
          <h2 className='title-company'>Ink Pressive Custom Screen Printing</h2>
          <div className='intro-btns'>
            {/* <button className='introBtn-one'>Get a Quote</button> */}
            <div className='introBtn-one'>
              <div className="translateOne"></div>
              <Link to='/services'>Get a Quote</Link>
            </div>
            {/* <button className='introBtn-two'>Find out More</button> */}
            <div className='introBtn-two'>
              <div className="translateTwo"></div>
              <Link to='/contact'>Find out More</Link>
            </div>
          </div>
          <br />
          {/* <div className='screenHomeImg' alt="screen priniting introduction"></div> */}
          <img
            src="https://images.unsplash.com/photo-1456456496250-d5e7c0a9b44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
            className='screenHomeImg'
            alt="screen priniting introduction"
          />
        </div>
        <DisplayCMSSection />
        <div className="home-specialze">
          <div className="boxPicImg">
            <h2>Specialize in</h2>
            <div className="spec-pic">
              <figure className="homecontentFig">
                <figcaption className="figCap">Sublimation</figcaption>
                <Link to='/services'><img className="spImg" src={beetleShirt} alt="sublimation" /></Link>
              </figure>
              <figure className="homecontentFig">
                <figcaption className="figCap">Vinyl</figcaption>
                <Link to='/services'><img className="spImg" src={onesy} alt="vinyl" /></Link>
              </figure>
              <figure className="homecontentFig">
                <figcaption className="figCap">Screen Printing</figcaption>
                <Link to='/services'><img className="spImg" src={basicWitch} alt="screen printing" /></Link>
              </figure>
            </div>
          </div>
        </div>
        <PhotoGallery />
      </main>
    </div>
  );
}

export default ContentComp;
