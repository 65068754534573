import firebase from 'firebase/app';
// import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: 'AIzaSyA6kvMSc5-Cq7f8mn8RVt3IR0QIlbjYCag',
  authDomain: 'ink-pressive-dev.firebaseapp.com',
  databaseURL: 'https://ink-pressive-dev-default-rtdb.firebaseio.com',
  projectId: 'ink-pressive-dev',
  storageBucket: 'ink-pressive-dev.appspot.com',
  messagingSenderId: '492433183742',
  appId: '1:492433183742:web:ba03e80e9ea4a31e3226af'
};




// firebase.initializeApp(firebaseConfig);
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

}

// export const auth = firebaseConfig.auth()

export default firebase;
// export const firebase = !fb.apps.length
//   ? fb.initializeApp(firebaseConfig)
//   : fb.app();
// export { projectStorage, projectFirestore };
