import React from 'react';
import ContentComp from '../Components/ContentComp';

import NavBar from '../Components/NavBar';
import FooterBar from '../Components/FooterBar';
import './HomePage.css';
const HomePage = props => (
  <div className="home-container">
    <NavBar />
    <ContentComp />
    <FooterBar />
  </div>
);

export default HomePage;
