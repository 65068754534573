import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../Routes/ProtectedRoute'
import ContactMessCMS from '../CmsAdmin/ContactMessCMS';
import CMSSection from '../CmsAdmin/CMSSection';
import OrdersCMS from '../CmsAdmin/OrdersCMS';
import CMSPhotoGallery from '../CmsAdmin/CMSPhotoGallery'
import Login from '../auth/Login';

const InkPressiveCMSRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path='/login'
        component={Login}
      />
      <ProtectedRoute
        exact
        path="/cmsContact"
        component={ContactMessCMS}
      />
      <ProtectedRoute
        exact
        path="/cmsOrders"
        component={OrdersCMS}
      />
      <ProtectedRoute
        exact
        path="/cmsEdit"
        component={CMSSection}
      />
      <ProtectedRoute
        exact
        path="/cmsGallery"
        component={CMSPhotoGallery}
      />
    </Switch>
  );
};

export default InkPressiveCMSRoutes;



/* <ProtectedRoute
        exact
        path="/cmsContact"
        render={routeProps => <ContactMessCMS {...routeProps} />}
      />
      <ProtectedRoute
        exact
        path="/cmsOrders"
        render={routeProps => <OrdersCMS {...routeProps} />}
      />
      <ProtectedRoute
        exact
        path="/cmsEdit"
        render={routeProps => <CMSSection {...routeProps} />}
      /> */