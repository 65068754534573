import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from '../firebase';
import 'firebase/firestore';
import NewCMSNav from './NewCMSNav';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '50px',
    background: 'grey'
  },
  shirtsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '5px',
    paddingLeft: '392px',
    paddingRight: '25px',
    paddingBottom: '10px'
  },
  InputBase: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1),
    color: 'white'
  },
  valInputBase: {
    border: '1px solid red',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    // padding: theme.spacing(1),
    color: 'white',
    marginLeft: '20px',
    padding: '10px',
    width: '25ch'
  },
  InputBase2: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    // padding: theme.spacing(1),
    color: 'white',
    padding: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  positionRoot: {
    marginLeft: '300px'
  },
  myH2: {
    // fontFamily: 'abel',
    marginLeft: '10px',
    color: '#fff'
  },
  myH1: {
    // fontFamily: 'abel',
    marginLeft: '10px',
    color: 'white'
  },
  errorText: {
    color: 'red'
  },
  chooseSizes: {
    border: '1px solid white',
    color: '#fff',
    borderRadius: theme.shape.borderRadius,
    height: '6vh',
    padding: theme.spacing(1)
  },
  multilineField: {
    border: '1px solid white',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    color: 'white',
    // width: '25ch'
    width: '100%',
    maxWidth: '950px'
  },
  buttonContainer: {
    marginLeft: '10px'
  },
  submitButtonStyle: {
    backgroundColor: 'lightslategray',
    color: 'white',
    marginTop: '10px',
    width: '100%',
    maxWidth: '950px'
  }
}));

const CMSSection = () => {
  const classes = useStyles();
  const history = useHistory()
  const [state, setState] = useState(({
    headingMessage: '',
    bodyMessage: ''
  }));
  const [uploader, setUploader] = useState({
    img_1: null,
    img_2: null,
    img_3: null,
    img_4: null,
  });


  const handleFieldInputs = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const fileSelect = async (e) => {
    let file = e.target.files[0]
    const base64 = await encodeImg(file)
    setUploader({ ...uploader, [e.target.name]: base64 })
    // console.log(base64);
  };

  const encodeImg = (file) => {

    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        res(fileReader.result)
      };

      fileReader.onerror = (error) => {
        rej(error)
      };
    })

  }

  const handleCMSSectionSubmit = (e) => {

    e.preventDefault();
    const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

    firebase
      .firestore()
      .collection('cmsSection')
      .doc('c62uM0VVNrJtzsyEhfar')
      .update({
        // need to stringIfy timeStamp
        cmsSection: {
          heading: state.headingMessage,
          imageItem_1: uploader.img_1,
          imageItem_2: uploader.img_2,
          imageItem_3: uploader.img_3,
          imageItem_4: uploader.img_4,
          body: state.bodyMessage,
          createdAt: timeStamp()
        },
      });
    // firebase
    //   .firestore()
    //   .collection('cmsSection')
    //   .add({
    //     // need to stringIfy timeStamp
    //     cmsSection: {
    //       heading: state.headingMessage,
    //       imageBackground: uploader.backgroundImg,
    //       imageItem_1: uploader.img_1,
    //       imageItem_2: uploader.img_2,
    //       imageItem_3: uploader.img_3,
    //       imageItem_4: uploader.img_4,
    //       body: state.bodyMessage,
    //       createdAt: timeStamp()
    //     },
    //   });

    setState({
      headingMessage: '',
      bodyMessage: ''
    })
    setUploader({
      img_1: null,
      img_2: null,
      img_3: null,
      img_4: null,
    })

    history.push('/cmsEdit')

  }

  // NOTE: Uploader will be changed to add a Link to either FaceBook or instagram


  // console.log(uploader);
  // console.log(object);

  return (
    <div>
      <NewCMSNav />
      <div className={classes.root}>
        <form onSubmit={handleCMSSectionSubmit} >
          <div className="testDiv">
            <div>
              <Typography style={{ color: '#fff' }} variant="h2">
                Ink Pressive Content Mangagment
              </Typography>
              <br />
              <Typography variant="h4" className={classes.myH1}>
                Message Heading
              </Typography>
              <InputBase
                id="outlined-full-width"
                className={classes.InputBase}
                style={{ margin: 8 }}
                onChange={handleFieldInputs}
                placeholder="Heading"
                name="headingMessage"
                fullWidth
                variant="outlined"
              />

              <br />
              <br />
            </div>
            <div>
              <br />
              {/* <Typography variant="h4" className={classes.myH2}>
                Choose background Image
              </Typography>
              <InputBase
                id="outlined-normal"
                type="file"
                onChange={(e) => fileSelect(e)}
                placeholder="Upload Image URL"
                className={classes.InputBase}
                name='backgroundImg'
                style={{ margin: 8 }}
                fullWidth
                variant="outlined"
              /> */}
              <Typography variant="h5" className={classes.myH2}>
                Choose Item Image # 1
              </Typography>
              <InputBase
                id="outlined-normal"
                type="file"
                onChange={(e) => fileSelect(e)}
                placeholder="Upload Image URL"
                className={classes.InputBase}
                name='img_1'
                style={{ margin: 8 }}
                variant="outlined"
              />
              <Typography variant="h5" className={classes.myH2}>
                Choose Item Image # 2
              </Typography>
              <InputBase
                id="outlined-normal"
                type="file"
                onChange={(e) => fileSelect(e)}
                placeholder="Upload Image URL"
                className={classes.InputBase}
                name='img_2'
                style={{ margin: 8 }}
                variant="outlined"
              />
              <Typography variant="h5" className={classes.myH2}>
                Choose Item Image # 3
              </Typography>
              <InputBase
                id="outlined-normal"
                type="file"
                onChange={(e) => fileSelect(e)}
                placeholder="Upload Image URL"
                className={classes.InputBase}
                name='img_3'
                style={{ margin: 8 }}
                variant="outlined"
              />
              <Typography variant="h5" className={classes.myH2}>
                Choose Item Image # 4
              </Typography>
              <InputBase
                id="outlined-normal"
                type="file"
                onChange={(e) => fileSelect(e)}
                placeholder="Upload Image URL"
                className={classes.InputBase}
                name='img_4'
                style={{ margin: 8 }}
                variant="outlined"
              />
            </div>
            <br></br>

            <br />
            <br />
            <div className={classes.myH1}>
              <Typography variant="h4">Content Body Section</Typography>
              <br />
              <InputBase
                label="Items and Qty"
                className={classes.multilineField}
                multiline
                rows={15}
                id="outlined-margin-normal"
                onChange={handleFieldInputs}
                placeholder="Body for Updates"
                name="bodyMessage"
                variant="outlined"
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                type="submit"
                // disabled={textState.disabled}
                className={classes.submitButtonStyle}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CMSSection;
