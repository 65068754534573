import React from 'react';
import { Link } from 'react-router-dom';
import './FooterBar.css';
import pic1 from '../img/pic1.jpg';

const FooterBar = () => {
  return (
    <div>
      <footer>
        {/* <div> */}
        <div className="footer-img">
          <Link exact to="/">
            <img src={pic1} alt="" />
          </Link>
        </div>
        <div className="social-cont">
          <div className="social-icons">
            <a href="https://www.facebook.com/InkPressive-595272567825432">
              <i className="fab fa-facebook-square fa-4x facebook"></i>
            </a>
            <a href="https://www.instagram.com/inkpressivellc/">
              <i className="fab fa-instagram-square fa-4x instgram"></i>
            </a>
          </div>
          <br />
          {/* <h6>@ ALL Rights Reserved Ink Pressive</h6> */}
        </div>
        <div>
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <button>Contact</button>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default FooterBar;
