import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import 'firebase/storage';
import 'firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import NewCMSNav from './NewCMSNav';
import ModalOrder from './ModalOrder';

// {message: {…}, id: "jhNs1g3ExEfWkSBONgW1"}
// id: "jhNs1g3ExEfWkSBONgW1"
// message:
// company: "asdadsfad"
// createdAt: t {seconds: 1609274633, nanoseconds: 963000000}
// email: "Chrisltd1@hotmail.com"
// firstName: "dfsd"
// hoodies: true
// large: "10"
// lastName: "f"
// longSleeves: true
// medium: "2"
// other: ""
// screenPrinting: true
// small: "34"
// sublimation: true
// tees: true
// xlargeQty: "21"
// xxlargeQty: "21"

const useStyles = makeStyles(theme => ({
  conContainer: {
    margin: '20px',
    backgroundColor: '#363b3d',
    padding: '15px',
    borderRadius: '5px'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  cardContainer: {
    margin: '0.5rem'
  },
  root: {
    minWidth: 400,
    maxWidth: 400,
    minHeight: 400,
    maxHeight: 400,
    overflow: 'scroll'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));



const OrdersCMS = () => {
  const classes = useStyles();
  const [grabData, setGrabData] = useState([]);

  useEffect(() => {
    const unSub = firebase
      .firestore()
      .collection('Service_orders')
      // .orderBy('createdAt', 'desc')
      .onSnapshot(snap => {
        let documents = [];
        snap.forEach(doc => {
          documents.push({ ...doc.data(), id: doc.id });
        });
        setGrabData(documents);
      });
    return () => unSub();
  }, []);



  function toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  const handleReadStatus = data => {
    // console.log(data);
    const readStatus = grabData.map(stat =>
      stat.id === data.id ? { ...stat, hasSeen: true } : stat
    );

    // console.log(readStatus);
    // const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
    firebase
      .firestore()
      .collection('Service_orders')
      .doc(data.id)
      .update({
        // need to stringIfy timeStamp
        message: {
          company: data.message.company,
          firstName: data.message.firstName,
          lastName: data.message.lastName,
          email: data.message.email,
          other: data.message.other,
          small: data.message.small,
          medium: data.message.medium,
          large: data.message.large,
          xlargeQty: data.message.xlargeQty,
          xxlargeQty: data.message.xxlargeQty,
          tees: data.message.tees,
          longSleeves: data.message.longSleeves,
          hoodies: data.message.hoodies,
          screenPrinting: data.message.screenPrinting,
          sublimation: data.message.sublimation,
          customerMessage: data.message.customerMessage,
          createdAt: data.message.createdAt
        },
        hasSeen: true
      });

    setGrabData(readStatus);
  };

  const handleDelete = (data) => {
    firebase
      .firestore()
      .collection('Service_orders')
      .doc(data.id)
      .delete()
      .then(() => {
        console.log('Document Successfully deleted');
      }).catch((err) => {
        console.log(err);
      })
  }

  // console.log(grabData);

  return (
    <div>
      <NewCMSNav />
      <div className={classes.conContainer}>
        <Title>Orders</Title>
        <div className={classes.messageContainer}>
          {grabData.map(data => (
            <div className={classes.cardContainer} key={data.id}>
              <Card
                className={classes.root}
                style={{
                  backgroundColor: data.hasSeen === false ? '#fff' : '#656769'
                }}
                variant="outlined"
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ borderBottom: '1px solid black' }}
                  >
                    Company: {data.message.company}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    Email: {data.message.email}
                  </Typography>
                  <br />
                  <Typography variant="h6" component="h2">
                    Name: {data.message.firstName} {data.message.lastName}
                  </Typography>
                  <br />
                  <Typography className={classes.pos} color="textSecondary">
                    Date:{' '}
                    {JSON.stringify(toDateTime(data.message.createdAt.seconds))}
                  </Typography>
                  <br />
                  <Typography variant="h6" component="h2">
                    Process:{' '}
                    {data.message.screenPrinting === true
                      ? 'Screen Printing'
                      : ''}{' '}
                    {data.message.sublimation === true ? 'Sublimation' : ''}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Shirts Type: {data.message.tees === true ? 'Tees' : ''}{' '}
                    {data.message.longSleeves === true ? 'Long Sleeves' : ''}{' '}
                    {data.message.hoodies === true ? 'Hoodies' : ''}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Sizes -
                    <br />
                    Small: {data.message.small} <br /> Medium:{' '}
                    {data.message.medium} <br /> Large: {data.message.large}{' '}
                    <br />
                    X-Large: {data.message.xlargeQty}
                    <br /> XX-Large: {data.message.xxlargeQty}
                  </Typography>
                  {/* {} */}
                  {data.message.uploader === null ? '' : <ModalOrder data={data} />}
                  {/* {} */}
                  <br />
                  <br />
                  <Typography
                    variant="body2"
                    component="p"
                    style={{
                      backgroundColor: 'lightgray',
                      padding: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    {'"Message"'}
                    <br />
                    {data.message.customerMessage === ' '
                      ? 'No message from customer'
                      : data.message.customerMessage}
                  </Typography>
                  <Button
                    onClick={() => handleReadStatus(data)}
                    style={{
                      backgroundColor: 'lightslategrey',
                      marginLeft: '0px',
                      marginTop: '10px'
                    }}
                    disabled={data.hasSeen === true}
                  >
                    SEEN MESSAGE
                  </Button>
                  <Button
                    onClick={() => handleDelete(data)}
                    style={{
                      backgroundColor: 'lightslategrey',
                      marginLeft: '160px',
                      marginTop: '10px'
                    }}
                  // disabled={data.hasSeen === true}
                  >
                    Delete
                  </Button>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrdersCMS;
