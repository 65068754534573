import React, { useContext, useState, useEffect, createContext } from 'react';
import firebase from '../firebase';
// import { auth } from '../firebase'
// import { createContext } from 'vm';
// import { loadPartialConfig } from '@babel/core';

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const auth = firebase.auth()
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logOut() {
        return auth.signOut()
    }

    useEffect(() => {
        const unsubribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubribe
    }, [auth])

    const value = {
        currentUser,
        login,
        logOut
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

