import React, { useState, useEffect } from 'react';
// import useToggle from '../hooks/useToggle';
import firebase from '../firebase';
import 'firebase/storage';
import 'firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Title from './Title';
import NewCMSNav from './NewCMSNav';

const useStyles = makeStyles(theme => ({
  conContainer: {
    margin: '20px',
    backgroundColor: '#262d38',
    borderRadius: '5px',
    padding: '10px'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  cardContainer: {
    margin: '0.5rem'
  },
  root: {
    minWidth: 275,
    maxWidth: 275,
    maxHeight: 200,
    overflow: 'scroll'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));

const ContactMessCMS = () => {
  const classes = useStyles();
  const [grabData, setGrabData] = useState([]);
  // const [seen, setSeen] = useToggle(grabData.message.hasSeen);

  useEffect(() => {
    const unSub = firebase
      .firestore()
      .collection('Contact_MSG')
      // .orderByChild('createdAt')
      .onSnapshot(snap => {
        let documents = [];
        snap.forEach(doc => {
          documents.push({ ...doc.data(), id: doc.id });
        });
        setGrabData(documents);
      });
    return () => unSub();
  }, []);

  function toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  const handleReadStatus = data => {
    // console.log(data);
    const readStatus = grabData.map(stat =>
      stat.id === data.id ? { ...stat, hasSeen: true } : stat
    );

    // console.log(readStatus);
    // const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
    firebase
      .firestore()
      .collection('Contact_MSG')
      .doc(data.id)
      .update({
        // need to stringIfy timeStamp
        message: {
          name: data.message.name,
          email: data.message.email,
          phoneNumber: data.message.phoneNumber,
          message: data.message.message,
          createdAt: data.message.createdAt
        },
        hasSeen: true
      });

    setGrabData(readStatus);
  };

  const handleDelete = (data) => {
    firebase
      .firestore()
      .collection('Contact_MSG')
      .doc(data.id)
      .delete()
      .then(() => {
        console.log('Document Successfully deleted');
      }).catch((err) => {
        console.log(err);
      })
  }

  // console.log(grabData);

  return (
    <div>
      <NewCMSNav />
      <div className={classes.conContainer}>
        <Title className={classes.title}>Recent Messages</Title>
        <div className={classes.messageContainer}>
          {grabData.map(data => (
            <div
              className={classes.cardContainer}
              key={data.message.phoneNumber}
            >
              <Card
                style={{
                  backgroundColor: data.hasSeen === false ? '#fff' : '#656769'
                }}
                className={classes.root}
                variant="outlined"
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ borderBottom: '1px solid black' }}
                  >
                    {data.message.name}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {data.message.email}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Phone: {data.message.phoneNumber}
                  </Typography>
                  <br />
                  <Typography className={classes.pos} color="textSecondary">
                    Date:{' '}
                    {JSON.stringify(toDateTime(data.message.createdAt.seconds))}
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    component="p"
                    style={{
                      backgroundColor: 'lightgray',
                      padding: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    {'"Message"'}
                    <br />
                    {data.message.message}
                  </Typography>
                </CardContent>
                <Button
                  onClick={() => {
                    handleReadStatus(data);
                  }}
                  style={{
                    backgroundColor: 'lightslategrey',
                    marginLeft: '15px',
                    marginBottom: '10px'
                  }}
                  disabled={data.hasSeen === true}
                >
                  Seen Message
                </Button>
                <Button
                  onClick={() => {
                    handleDelete(data);
                  }}
                  style={{
                    backgroundColor: 'lightslategrey',
                    marginLeft: '15px',
                    marginBottom: '10px'
                  }}
                // disabled={data.hasSeen === true}
                >
                  Delete
                </Button>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactMessCMS;
