import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from '../firebase';
import 'firebase/firestore';
import NewCMSNav from './NewCMSNav';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '50px',
        background: 'grey'
    },
    shirtsRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '5px',
        paddingLeft: '392px',
        paddingRight: '25px',
        paddingBottom: '10px'
    },
    InputBase: {
        border: '1px solid white',
        borderRadius: theme.shape.borderRadius,
        height: '6vh',
        padding: theme.spacing(1),
        color: 'white'
    },
    valInputBase: {
        border: '1px solid red',
        borderRadius: theme.shape.borderRadius,
        height: '6vh',
        // padding: theme.spacing(1),
        color: 'white',
        marginLeft: '20px',
        padding: '10px',
        width: '25ch'
    },
    InputBase2: {
        border: '1px solid white',
        borderRadius: theme.shape.borderRadius,
        height: '6vh',
        // padding: theme.spacing(1),
        color: 'white',
        padding: '10px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch'
    },
    positionRoot: {
        marginLeft: '300px'
    },
    myH2: {
        // fontFamily: 'abel',
        marginLeft: '10px',
        color: '#fff'
    },
    myH1: {
        // fontFamily: 'abel',
        marginLeft: '10px',
        color: 'white'
    },
    errorText: {
        color: 'red'
    },
    chooseSizes: {
        border: '1px solid white',
        color: '#fff',
        borderRadius: theme.shape.borderRadius,
        height: '6vh',
        padding: theme.spacing(1)
    },
    multilineField: {
        border: '1px solid white',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        color: 'white',
        // width: '25ch'
        width: '100%',
        maxWidth: '950px'
    },
    buttonContainer: {
        marginLeft: '10px'
    },
    submitButtonStyle: {
        backgroundColor: 'lightslategray',
        color: 'white',
        marginTop: '10px',
        width: '100%',
        maxWidth: '950px'
    }
}));

const CMSPhotoGallery = () => {
    const classes = useStyles();
    const history = useHistory()
    const [state, setState] = useState(({
        photoUrl_1: '',
        photoUrl_2: '',
        photoUrl_3: '',
        photoUrl_4: '',
        photoUrl_5: '',
        photoUrl_6: '',
        photoUrl_7: '',
        photoUrl_8: '',
    }));

    const [uploader, setUploader] = useState({
        img_1: null,
        img_2: null,
        img_3: null,
        img_4: null,
        img_5: null,
        img_6: null,
        img_7: null,
        img_8: null
    });


    const handleFieldInputs = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }



    const fileSelect = async (e) => {
        let file = e.target.files[0]
        const base64 = await encodeImg(file)
        setUploader({ ...uploader, [e.target.name]: base64 })
        // console.log(base64);
    };

    const encodeImg = (file) => {

        return new Promise((res, rej) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                res(fileReader.result)
            };

            fileReader.onerror = (error) => {
                rej(error)
            };
        })

    }

    // Collections id
    // oSqaE2aPwwBi5XO2q4lA

    const handleCMSSectionSubmit = (e) => {
        e.preventDefault();
        const timeStamp = firebase.firestore.FieldValue.serverTimestamp;


        // sBMPOK8A3NXfx2qNcxpD

        firebase
            .firestore()
            .collection('cmsPhotosUrls')
            .doc('1BJ2bhHICXbGRSh5G5R6')
            .update({
                cmsPhotosUrls: {
                    photoUrls: [
                        { url: state.photoUrl_1, photo: uploader.img_1 },
                        { url: state.photoUrl_2, photo: uploader.img_2 },
                        { url: state.photoUrl_3, photo: uploader.img_3 },
                        { url: state.photoUrl_4, photo: uploader.img_4 },
                        { url: state.photoUrl_5, photo: uploader.img_5 },
                        { url: state.photoUrl_6, photo: uploader.img_6 },
                        { url: state.photoUrl_7, photo: uploader.img_7 },
                        { url: state.photoUrl_8, photo: uploader.img_8 },
                    ],
                    createdAt: timeStamp()
                }
            })
        setState({
            ph8toUrl_1: '',
            ph8toUrl_2: '',
            photoUrl_3: '',
            photoUrl_4: '',
            photoUrl_5: '',
            photoUrl_6: '',
            photoUrl_7: '',
            photoUrl_8: '',
        })
        setUploader({
            img_1: null,
            img_2: null,
            img_3: null,
            img_4: null,
            img_5: null,
            img_6: null,
            img_7: null,
            img_8: null
        })

        history.push('/cmsGallery')

    }

    // Photo and Url objects below

    // pAndU_4: {
    //     img: uploader.img_4,
    //     emUrl: state.photoUrl_4
    // },
    // pAndU_5: {
    //     img: uploader.img_5,
    //     emUrl: state.photoUrl_5
    // },
    // pAndU_6: {
    //     img: uploader.img_6,
    //     emUrl: state.photoUrl_6
    // },
    // pAndU_7: {
    //     img: uploader.img_7,
    //     emUrl: state.photoUrl_7
    // },
    // pAndU_8: {
    //     img: uploader.img_8,
    //     emUrl: state.photoUrl_8
    // },

    // NOTE: Uploader will be changed to add a Link to either FaceBook or instagram


    // console.log(state);
    // console.log(uploader);

    return (
        <div>
            <NewCMSNav />
            <div className={classes.root}>
                <form onSubmit={handleCMSSectionSubmit} >
                    <div className="testDiv">
                        <div>
                            <Typography style={{ color: '#fff' }} variant="h2">
                                Ink Pressive Photo Gallery
                            </Typography>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div>
                            <br />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 1
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_1'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 1
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_1'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 2
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_2'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 2
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_2'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 3
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_3'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 3
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_3'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 4
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_4'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 4
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_4'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 5
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_5'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 5
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_5'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 6
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_6'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 6
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_6'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 7
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_7'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 7
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_7'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Item Image # 8
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="file"
                                onChange={fileSelect}
                                placeholder="Upload Image URL"
                                className={classes.InputBase}
                                name='img_8'
                                style={{ margin: 8 }}
                                variant="outlined"
                            // fullWidth 
                            />
                            <Typography variant="h5" className={classes.myH2}>
                                Choose Embed Url # 8
              </Typography>
                            <InputBase
                                id="outlined-normal"
                                type="text"
                                onChange={handleFieldInputs}
                                placeholder="Upload URL"
                                className={classes.InputBase}
                                name='photoUrl_8'
                                style={{ margin: 8 }}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <br></br>

                        <br />
                        <br />
                        {/* <div className={classes.myH1}>
                            <Typography variant="h4">Content Body Section</Typography>
                            <br />
                            <InputBase
                                label="Items and Qty"
                                className={classes.multilineField}
                                multiline
                                rows={15}
                                id="outlined-margin-normal"
                                onChange={handleFieldInputs}
                                placeholder="Body for Updates"
                                name="bodyMessage"
                                variant="outlined"
                                fullWidth
                            />
                        </div> */}
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                type="submit"
                                // disabled={textState.disabled}
                                className={classes.submitButtonStyle}
                            >
                                Submit
              </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CMSPhotoGallery;


// firebase
// .firestore()
// .collection('cmsPhotosUrls')
// .add({
    // .firestore()
    // .collection('cmsPhotos')
    // .doc('oSqaE2aPwwBi5XO2q4lA')
    // .update({
        // need to stringIfy timeStamp
        // photoAndUrl: {
        //     pAndU_1: {
        //         img: uploader.img_1,
        //         emUrl: state.photoUrl_1
        //     },
        //     pAndU_2: {
        //         img: uploader.img_2,
        //         emUrl: state.photoUrl_2
        //     },
        //     pAndU_3: {
        //         img: uploader.img_3,
        //         emUrl: state.photoUrl_3
        //     },
        //     pAndU_4: {
        //         img: uploader.img_4,
        //         emUrl: state.photoUrl_4
        //     },
        //     pAndU_5: {
        //         img: uploader.img_5,
        //         emUrl: state.photoUrl_5
        //     },
        //     pAndU_6: {
        //         img: uploader.img_6,
        //         emUrl: state.photoUrl_6
        //     },
        //     pAndU_7: {
        //         img: uploader.img_7,
        //         emUrl: state.photoUrl_7
        //     },
        //     pAndU_8: {
        //         img: uploader.img_8,
        //         emUrl: state.photoUrl_8
        //     },
        // }
        // photoAndUrl: {
        //     pAndU_1: [uploader.img_1, state.photoUrl_1],
        //     pAndU_2: [uploader.img_2, state.photoUrl_2],
        //     pAndU_3: [uploader.img_3, state.photoUrl_3],
        //     pAndU_4: [uploader.img_4, state.photoUrl_4],
        //     pAndU_5: [uploader.img_5, state.photoUrl_5],
        //     pAndU_6: [uploader.img_6, state.photoUrl_6],
        //     pAndU_7: [uploader.img_7, state.photoUrl_7],
        //     pAndU_8: [uploader.img_8, state.photoUrl_8],
        // }
        // photoAndUrl: {
        //     pAndU_1: { photo_1: uploader.img_1, url_1: state.photoUrl_1 },
        //     pAndU_2: { photo_2: uploader.img_2, url_2: state.photoUrl_2 },
        //     pAndU_3: { photo_3: uploader.img_3, url_3: state.photoUrl_3 },
        //     pAndU_4: { photo_4: uploader.img_4, url_4: state.photoUrl_4 },
        //     pAndU_5: { photo_5: uploader.img_5, url_5: state.photoUrl_5 },
        //     pAndU_6: { photo_6: uploader.img_6, url_6: state.photoUrl_6 },
        //     pAndU_7: { photo_7: uploader.img_7, url_7: state.photoUrl_7 },
        //     pAndU_8: { photo_8: uploader.img_8, url_8: state.photoUrl_8 },
        // }
    // });